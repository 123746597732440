import * as React from "react";
import {withLayout} from "../components/Layout";
import { graphql } from "gatsby";

function fmtDate(dateString: string) {
  const d = new Date(dateString);
  return d.toLocaleDateString("de-DE", {day: "2-digit", month: "2-digit", year: "numeric"});
}

const ShowsPage = (props: any) => {
  const shows = props.data.shows.nodes;

  function showText(show) {
    if (show.text !== "") {
      return (<p>
        {show.text}
      </p>);
    }
    return null;
  }

  function showLink(show) {
    if (show.link !== null && show.link !== "") {
      return (<p>
        Weitere Infos: <a href={show.link}>{show.link}</a>
        </p>
      );
    }
    return null;
  }

  return (
    <div className="pa4">
      <h1>Auftritte</h1>
      {
        shows.map((show) => {
          if (new Date(show.publish_date) > new Date()) {
            return (null);
          } else {
            return (
              <div key={show.id}>
                <h3>
                  {fmtDate(show.show_date)}: {show.location}
                </h3>
                <div className={`ph4`}>
                  {showText(show)}
                  {showLink(show)}
                </div>
              </div>
            );
          }})
      }
    </div>
    );
};

export default withLayout(ShowsPage);

export const pageQuery = graphql`
  query GetShows {
    shows: allStrapiShow(sort: {fields: show_date, order: DESC}) {
      nodes {
        id
        show_date
        publish_date
        location
        link
        text
      }
    }
  }
`;
